import React from "react"
import { graphql } from "gatsby"
import Layout from "gatsby-theme-wirehead-bulma/src/layout"
import TwoCol from "gatsby-theme-wirehead-bulma/src/twocol"
import SEO from "gatsby-theme-wirehead-bulma/src/seo"
import SocialMenu from "../../components/socialmenu"
import SocialShare from "../../components/socialshare"
import Breadcrumbs from "gatsby-theme-wirehead-bulma/src/breadcrumbs"

const IndexPage = ({ data, location, pageContext }) => {
  const vsts = data.workbookYaml.vsts.map((vst, x) => {
    return (
      <tr key={x}>
        <th>
          <a href={vst.url}>{vst.name}</a>
        </th>
        <td>{vst.notes}</td>
        <td>{vst.license}</td>
      </tr>
    )
  })
  return (
    <Layout>
      <SEO
        title={data.workbookYaml.title}
        pathname={location.pathname}
        description={data.workbookYaml.description}
      />
      <Breadcrumbs
        depth={pageContext.depth}
        breadcrumbs={pageContext.breadcrumbs}
      />
      <TwoCol
        left={
          <div className="content">
            <table className="table">
              <thead>
                <tr>
                  <th>VST</th>
                  <th>Notes</th>
                  <th>License</th>
                </tr>
              </thead>
              <tbody>{vsts}</tbody>
            </table>
          </div>
        }
        right={
          <>
            <aside className="menu">
              <SocialMenu />
            </aside>
            <SocialShare link={location.pathname} />
          </>
        }
      />
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  {
    workbookYaml {
      id
      date
      description
      title
      type
      vsts {
        license
        name
        notes
        url
      }
    }
  }
`

export const frontmatter = {
  title: "Useful VSTs",
}